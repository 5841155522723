.botaoEdit{
    padding: 10px 30px;
    font-size: 22px;
    background-color: orange;
    border: initial;
    outline: initial;
    color: #fff;
    border-radius: 30px;
    font-weight: normal;
    text-align: center;
    font-family: 'Oswald', sans-serif;
    margin-left: 10px; 
    cursor: pointer;
    transition: .2s ;
}
.botaoEdit:active {
    transform: scale(.9);
}