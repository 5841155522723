@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");

@import url("https://fonts.googleapis.com/css?family=Oswald&display=swap");

body {
  padding: 0px;
  margin: 0px;
  font-family: "Roboto", sans-serif;
  background-color: #051e5a;
  background-image: url(./assets/images/base/fundogeral.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.fundoLogin {
  display: flex;
  background-image: url(./assets/images/base/fundogeral.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
  height: 95vh;
  padding: 20px;
}

h1,
h2,
h3 {
  font-family: "Oswald", sans-serif;
}

.containerRegulamento {
  height: 400px;
  margin-top: 30px;
  margin-bottom: 30px;
  background-color: #fff;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 30px;
  text-align: left;
  color: #222;
}
.textoRegulamento {
  height: 400px;
  overflow-y: auto;
}
.containerMedicamento {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 50px;
  height: 420px;
}

.botaoRegulamento {
  padding: 10px 30px;
  font-size: 22px;
  background-color: #006796;
  border: initial;
  outline: initial;
  color: #fff;
  border-radius: 30px;
  font-weight: normal;
  text-align: center;
  max-width: 240px;
  font-family: "Oswald", sans-serif;
}
.botaoEditFale {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  background-color: orange;
  border: initial;
  outline: initial;
  color: #fff;
  border-radius: 30px;
  font-weight: normal;
  text-align: center;
  font-family: "Oswald", sans-serif;
  position: absolute;
  right: 20px;
  bottom: -43px;
}

.botaoMedicamentos {
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  outline: initial;
  border-radius: 100px;
  font-weight: bold;
  text-align: center;
  font-family: "Oswald", sans-serif;
  cursor: pointer;
  transition: 0.2s;
  color: #fff;
}

.botaoMedicamentos:hover {
  -webkit-animation: none;
  transform: scale(1.2);
  color: #000;
}

.botaoEditMural {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  background-color: orange;
  border: initial;
  outline: initial;
  color: #fff;
  border-radius: 30px;
  font-weight: normal;
  text-align: center;
  font-family: "Oswald", sans-serif;
  position: absolute;
  right: 20px;
  bottom: -22px;
  cursor: pointer;
  transition: 0.2s;
}
.botaoEditMural:active {
  transform: scale(0.9);
}

.tituloCaps {
  padding: 10px 30px;
  font-size: 28px;
  background-color: #006796;
  color: #fff;
  border-radius: 30px;
  text-align: center;
  max-width: 240px;
  font-family: "Oswald", sans-serif;
}

.baseConteudo {
  min-height: 500px;
  -webkit-animation: baseconteudo 0.3s ease-out;
  margin-bottom: 8px;
}

@keyframes baseconteudo {
  from {
    opacity: 0;
    margin-top: 50px;
  }
  to {
    opacity: 1;
    margin-top: 0px;
  }
}

.baseMuralNew {
  background-color: white;
  padding: 10px;
  border-radius: 12px;
  flex-basis: calc(33% - 40px);
  margin-top: 15px;
  margin-bottom: 15px;
  height: 150px;
  position: relative;
  margin-right: 10px;
  margin-left: 10px;
  -webkit-animation: enterMuralNew 0.3s ease-out;
}

@keyframes enterMuralNew {
  from {
    opacity: 0;
    transform: scaleX(0) scaleY(0);
  }
  to {
    opacity: 1;
    transform: scaleX(1) scaleY(1);
  }
}

@keyframes baseconteudo {
  from {
    opacity: 0;
    margin-top: 50px;
  }
  to {
    opacity: 1;
    margin-top: 0px;
  }
}

.baseMenuHidden {
  display: flex;
  justify-content: center;
  /* position: absolute; */
  bottom: 40px;
  width: 100%;
  max-width: 1280px;
}

.baseMenu {
  box-shadow: 0px 0px 35px #00d9ff85;
  display: flex;
  justify-content: space-between;
  /* position: absolute; */
  /* bottom: 40px; */
  width: 100%;
  width: 1280px;
  min-width: 500px;
  bottom: 10px;
  
}

.bodyBase {
  display: flex;
  flex-direction: column;
  padding: 20px;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  min-height: calc(100vh - 40px);
}

.baseMenuAnima {
  -webkit-animation: menustart 0.5s;
}

@keyframes menustart {
  from {
    transform: scaleX(0) scaleY(1);
  }
  to {
    transform: scaleX(1) scaleY(1);
  }
}

.baseMenu div {
  color: #fff;
  cursor: pointer;
  height: 130px;
  background-color: #051e5a;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: 0.2s;
  font-size: 18px;
  font-family: "Oswald", sans-serif;
  text-align: center;
  height: 100px;
  font-size: 14px;
}

.baseMenu div img {
  margin-bottom: 10px;
  transition: 0.8s;
}

.baseMenu div:hover,
.baseMenu div:nth-child(1):hover,
.baseMenu div:nth-child(2):hover,
.baseMenu div:nth-child(3):hover,
.baseMenu div:nth-child(4):hover,
.baseMenu div:nth-child(5):hover,
.baseMenu div:nth-child(6):hover,
.baseMenu div:nth-child(7):hover,
.baseMenu div:nth-child(8):hover,
.baseMenu div:nth-child(9):hover {
  background-color: orange;
}

.baseMenu div:hover img {
  transform: scale(1.3);
}

.baseMenu div:nth-child(1) {
  background-color: #003696;
}
.baseMenu div:nth-child(2) {
  background-color: #0040a7;
}
.baseMenu div:nth-child(3) {
  background-color: #0046bb;
}
.baseMenu div:nth-child(4) {
  background-color: #0052de;
}
.baseMenu div:nth-child(5) {
  background-color: #0058e3;
}
.baseMenu div:nth-child(6) {
  background-color: #005de6;
}
.baseMenu div:nth-child(7) {
  background-color: #0066f2;
}
.baseMenu div:nth-child(8) {
  background-color: #006dfa;
}
.baseMenu div:nth-child(9) {
  background-color: #006cea;
}

.baseMenu div.ativo {
  background-color: #edc620;
}

.w10 {
  width: 10%;
}
.w60 {
  width: 60%;
}
.w60 {
  width: 60%;
}

.flexRow {
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.tituloSecundario {
  margin-left: 20px;
  font-size: 32px;
  color: #fff;
  font-family: "Oswald", sans-serif;
}

.subtitulo1 {
  margin-left: 20px;
  font-size: 18px;
  color: #fff;
  font-family: "Oswald", sans-serif;
  margin-top: 7px;
}

.loginTitulo {
  font-size: 48px;
  color: #fff;
  font-family: "Oswald", sans-serif;
}

.btPremios {
  width: 68px;
  height: 68px;
  display: flex;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  border-radius: 60px;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
  transition: 0.3s;
  border: solid 2px #fff0;
  -webkit-animation: menupremios 4s infinite linear;
}

.btPremios:hover {
  -webkit-animation: none;
  transform: scale(1.2);
}

@keyframes menupremios {
  0% {
    border: solid 2px #fff0;
  }
  50% {
    border: solid 2px white;
  }
  100% {
    border: solid 2px #fff0;
  }
}

.baseTable {
  background-color: white;
  min-height: 500px;
  border-radius: 16px;
  overflow: hidden;
}

.headerTable {
  background-color: #ccc;
  min-height: 40px;
  margin: 8px;
  padding: 0px 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.headerTable strong {
  color: rgb(47, 119, 146);
  font-family: "Oswald", sans-serif;
  font-size: 13px;
}

.tableMensagem {
  margin: 40px 0px;
  color: rgb(47, 119, 146);
  font-family: "Oswald", sans-serif;
  font-size: 16px;
  text-align: center;
}

.logoBase {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.logoBase .titulo1 {
  color: #fff;
  font-family: "Oswald", sans-serif;
  font-size: 18px;
}
.logoBase .titulo1:last-child {
  color: #edc620;
}

.basePill {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pill {
  display: flex;
  background-color: orange;
  margin-right: 15px;
  color: white;
  border-radius: 15px;
  padding: 6px 12px;
  font-family: "Oswald", sans-serif;
  font-size: 14px;
  min-width: 150px;
  justify-content: space-evenly;
  cursor: pointer;
  transition: 0.2s;
}
.pill:hover {
  color: #222;
  transform: scale(1.1);
}

.pillAvatar {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: tomato;
}

.customCheck {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.customCheck input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: rgba(255, 255, 255, 0.3);
  border: solid 1px white;
}

.customCheck:hover input ~ .checkmark {
  background-color: rgba(255, 255, 255, 0.6);
}

.customCheck input:checked ~ .checkmark {
  background-color: white;
}

.customCheck:after {
  content: "";
  position: absolute;
  display: none;
}

.customCheck input:checked ~ .checkmark:after {
  display: block;
}

.flex2 {
  flex: 2;
}
.flex1 {
  flex: 1;
}
.w100 {
  width: 100px;
}
.flexTopo {
  align-items: center;
  display: flex;
}
.primeiraLinha {
  color: #fff;
  font-weight: bold;
  justify-content: flex-end;
  font-family: "Oswald", sans-serif;
}

/* filtro */
.baseFilter {
  background-color: #006796;
  border-radius: 20px;
  color: white;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.baseFilter input {
  border-style: hidden;
  background-color: #006796;
  width: 80%;
  padding: 10px;
  text-align: right;
  color: white;
  outline: initial;
}
.baseFilter input::placeholder {
  color: white;
}

.baseFilter select {
  max-width: 200px;
  padding: 10px;
  color: white;
  background-color: transparent;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -ms-appearance: none;
}

/* profile */
.baseAvatar {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.baseAvatar .avatar {
  width: 200px;
  height: 200px;
  border: solid 6px white;
  border-radius: 120px;
}
.cardProfile {
  flex: 3;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 20px;
  padding: 32px;
  margin-left: 32px;
  max-height: 400px;
  overflow: auto;
}

.cardProfile .dados {
  margin-bottom: 16px;
  flex-direction: column;
  display: flex;
}

.cardProfile .dados strong {
  color: #707070;
  font-family: "Oswald", sans-serif;
  font-size: 18px;
}

.cardProfile .dados span {
  color: #006796;
  font-family: "Oswald", sans-serif;
  font-size: 24px;
}

.miniMenu {
  background-color: blue;
  width: 80px;
  height: 80px;
  margin: 0px;
  cursor: pointer;
  color: white;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  align-items: center;
  transition: 0.2s;
  -webkit-animation: menuanime 4s infinite linear;
}

.miniMenu:hover {
  -webkit-animation: none;
  transform: scale(1.2);
}

@keyframes menuanime {
  0% {
    border: solid 1px #fff0;
  }
  50% {
    border: solid 1px white;
    transform: scale(1.1);
  }
  100% {
    border: solid 1px #fff0;
  }
}

.baseModal {
  background-color: #fff;
  width: 400px;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-direction: column;
  border-radius: 20px;
  -webkit-animation: enterMuralNew 0.3s ease-out;
}

/* CHARTS */

.divChart {
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: "Oswald", sans-serif;
  -webkit-animation: startgraph 2s;
}
.divChartTitulo {
  color: #fff;
  text-transform: uppercase;
  font-size: 18px;
  margin-bottom: 5px;
}
.divChartValor {
  font-size: 17px;
  margin-bottom: 5px;
}
.divCharValorRodape {
  font-size: 17px;
  text-align: center;
  /* margin-top: 5px; */
}
.divChartPorcentagem {
  font-size: 95px;
  color: #fff;
  font-weight: bold;
  border-radius: 100%;
  margin-top: 2px;
  margin-left: 2px;
  height: 216px;
  width: 216px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(11, 0, 205);
  background: linear-gradient(130deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
}

.divChartPorcentagemEquilibrio {
  font-size: 90px;
  color: #fff;
  font-weight: bold;
  border-radius: 100%;
  margin-top: 2px;
  margin-left: 2px;
  height: 216px;
  width: 216px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(11, 0, 205);
  background: linear-gradient(130deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
}

.divChartPorcentagemBase {
  font-weight: bold;
  border-radius: 100%;
  height: 220px;
  width: 220px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.divChartPorcento {
  font-size: 20px;
  margin-top: -30px;
  font-family: Arial, sans-serif;
}

.liquid1 {
  -webkit-animation: rotation 30s infinite linear;
  width: 240px;
  position: absolute;
}

@keyframes rotation {
  0% {
    opacity: 0;
    transform: rotate(0deg);
  }
  25% {
    opacity: 0.2;
    transform: rotate(90deg);
  }
  50% {
    opacity: 0.5;
    transform: rotate(180deg);
  }
  100% {
    opacity: 0;
    transform: rotate(359deg);
  }
}

.liquid2 {
  -webkit-animation: rotation2 50s infinite linear;
  width: 180px;
  position: absolute;
}

@keyframes rotation2 {
  0% {
    opacity: 0.2;
    transform: rotate(0deg);
  }
  25% {
    opacity: 0;
    transform: rotate(90deg);
  }
  50% {
    opacity: 0.2;
    transform: rotate(180deg);
  }
  100% {
    opacity: 0.5;
    transform: rotate(359deg);
  }
}

@keyframes startgraph {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.baseCharts {
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
}

.containerObjetivos {
  display: flex;
  flex-direction: column;
  margin: 0px 100px;
  height: calc(100vh - 350px);
  justify-content: center;
}

.graficoBase {
  /* margin-top: 15px; */
  width: 260px;
  margin-left: 0px;
  position: absolute;
}

.graficoContBall {
  /* margin-top: 15px; */
  width: 275px;
  height: 275px;
  margin-left: 0px;
  display: flex;
  position: absolute;
  transform: rotate(-45deg);
}
.graficoContPin {
  width: 275px;
  height: 20px;
  align-self: center;
}

.baseTableConteudo {
  max-height: 440px;
  overflow: auto;
}

@media screen and (max-width: 1366px) {
  .bodyBase {
    padding: 10px;
    max-width: 1180px;
    min-height: calc(100vh - 20px);
  }
  .baseMenuHidden {
    bottom: 10px;
    max-width: 1180px;
  }

  .baseMenu {
    bottom: 10px;
    max-width: 1180px;
  }

  .baseMenu div {
    height: 100px;
    font-size: 14px;
  }

  .baseMenu div img {
    margin-bottom: 4px;
  }

  .baseTable {
    min-height: 320px;
  }
  .baseTableConteudo {
    max-height: 320px;
  }
  .btPremios {
    width: 48px;
    height: 48px;

    font-size: 9px;

    margin-bottom: 10px;
    border-radius: 30px;
  }

  .tituloCaps {
    padding: 6px 20px;
    font-size: 20px;
    background-color: #006796;
    color: #fff;
    border-radius: 30px;
    text-align: center;
    max-width: 180px;
    font-family: "Oswald", sans-serif;
  }
}
